import React, { useState, useContext } from 'react'
import { walletImages } from 'src/constant'
import { Box, Paper, Typography, makeStyles } from '@material-ui/core'
import ConfirmationDialog from './modals/ConfirmationDialog'
import { deleteAPIHandler } from 'src/Apiconfig/service'
import { AuthContext } from 'src/context/Auth'
import { toast } from 'react-hot-toast'
import ScreenLoader from './ScreenLoader'
import EditExchange from './modals/EditExchange'

const useStyles = makeStyles((theme) => ({
  iconColor: {
    '& img': {
      marginLeft: '10px',
      cursor: 'pointer',
    },
  },
}))
export default function WalletCard({ data, index }) {
  const classes = useStyles()
  const auth = useContext(AuthContext)
  const { userData } = auth
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  //function to delete the particular selected data
  const deleteWalletHandler = async () => {
    try {
      setIsProcessing(true)
      await deleteAPIHandler({
        endPoint: 'wallet',
        userId: userData?.uid,
        id: data?.walletId,
      })
      toast.success('Wallet has been deleted successfully!')
      auth.getAllAddedWallets()
      setOpenDelete(false)
      setIsProcessing(false)
    } catch (error) {
      console.log(error)
      setIsProcessing(false)
    }
  }

  return (
    <Paper elevation={1} key={`walletCard${index}`}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className="dflexBetween">
          <img
            src={data && walletImages(data.walletName)}
            alt=""
            className="icons"
          />
          <Typography variant="body2" color="secondary">
            {data && data.walletName}
          </Typography>
        </Box>
        <Box className={`${classes.iconColor} dflexEnd `}>
          <img
            src="images/edit.svg"
            alt="edit portfolio"
            onClick={() => setOpenEdit(true)}
          />
          <img
            src="images/delete.svg"
            alt="edit delete"
            onClick={() => setOpenDelete(true)}
          />
        </Box>
      </Box>
      {openDelete && (
        <ConfirmationDialog
          title="Delete Confirmation"
          description={`Are you sure want to delete ${data?.walletName}?`}
          open={openDelete}
          close={() => setOpenDelete(false)}
          onClick={deleteWalletHandler}
        />
      )}
      {isProcessing && (
        <ScreenLoader
          open={isProcessing}
          close={() => setIsProcessing(false)}
        />
      )}
      {openEdit && (
        <EditExchange
          auth={auth}
          open={openEdit}
          walletId={data?.walletId}
          userId={userData?.uid}
          close={() => setOpenEdit(false)}
        />
      )}
    </Paper>
  )
}
