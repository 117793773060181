export function trimAddress(address) {
  // Check if the address is valid and has at least 10 characters
  if (!address || address.length < 10) {
    return address
  }

  // Check if the screen width is less than 768 pixels (typical mobile width)
  if (window.innerWidth < 768) {
    // Get the first 6 and last 4 characters of the address
    const start = address.slice(0, 6)
    const end = address.slice(-4)

    // Return the trimmed address
    return `${start}...${end}`
  }

  // Return the full address for non-mobile devices
  return address
}

export const walletList = [
  {
    title: 'Wallets',
  },
  {
    walletName: 'Coinbase Wallet',
    image: 'images/coinlist/coinbase_big.svg',
  },
  {
    walletName: 'MetaMask Wallet',
    image: 'images/coinlist/metamask_big.svg',
  },
  {
    title: 'Exchanges',
  },
]
export const walletList1 = [
  {
    walletName: 'Coinbase Wallet',
    image: 'images/coinlist/coinbase_big.svg',
  },
  {
    walletName: 'MetaMask Wallet',
    image: 'images/coinlist/metamask_big.svg',
  },

  {
    walletName: 'Biance',
    image: 'images/coinlist/binance.svg',
  },
  {
    walletName: 'Coinbase',
    image: 'images/coinlist/coinbase.svg',
  },
  {
    walletName: 'Kraken',
    image: 'images/coinlist/kraken.svg',
  },
  {
    walletName: 'KuCoin',
    image: 'images/coinlist/kucoin.svg',
  },
  {
    walletName: 'Bitstamp',
    image: 'images/coinlist/bitstamp.svg',
  },
  {
    walletName: 'Bitfnex',
    image: 'images/coinlist/bitfinex.svg',
  },
  {
    walletName: 'OKX',
    image: 'images/coinlist/okx.svg',
  },
]
export function camelCaseToSentence(str) {
  // Insert spaces before every uppercase letter (excluding the first letter)
  const sentence = str.replace(/([A-Z])/g, ' $1')

  // Capitalize the first letter of the resulting string
  return sentence.charAt(0).toUpperCase() + sentence.slice(1)
}
export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 10)}...${add?.slice(add.length - 4)}`
  return sortAdd
}
export function toSentenceCase(inputString) {
  // Split the input string into words
  const words = inputString?.split(/[_\s]+/)

  // Capitalize the first letter of each word
  const sentenceCaseWords = words?.map((word) => {
    const lowercaseWord = word?.toLowerCase()
    return lowercaseWord?.charAt(0)?.toUpperCase() + lowercaseWord?.slice(1)
  })

  // Join the words back together with spaces
  const sentenceCaseString = sentenceCaseWords?.join(' ')

  return sentenceCaseString
}
export function prettierString(inputString) {
  // Convert the entire string to lowercase first
  inputString = inputString?.toLowerCase()
  const check = inputString?.includes(' ')
  if (check) {
    // Split the string into words
    const words = inputString?.split(' ')

    // Capitalize the first letter of each word
    const sentenceCaseWords = words.map((word) => {
      // Handle words with apostrophes (e.g., "I'm")
      if (word.includes("'")) {
        const parts = word.split("'")
        return parts
          .map((part) => part.charAt(0)?.toUpperCase() + part.slice(1))
          .join("'")
      } else {
        return word.charAt(0)?.toUpperCase() + word.slice(1)
      }
    })
    // Join the words back into a sentence
    const sentenceCaseString = sentenceCaseWords.join(' ')

    return sentenceCaseString
  } else {
    return toSentenceCase(inputString)
  }
}

export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }
    return timeLeft
  } else {
    return false
  }
}

//function to format the number to percentage
export function formatAsPercentage(num) {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  }).format(num / 100)
}

//function generate a user unique avtar
export function walletQRGenerator(address) {
  return `https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${address}=UTF-8`
}

//function to format the number to USD
export const currencyFormatter = (value) => {
  let formatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formatter.format(value)
}

//function to format the number to perfect number with ","
export const setCryptoDecimals = (amt) => {
  amt = exponentialToDecimal(amt || 0)

  amt = amt?.replace(',', '')
  let arr = amt?.toString().split('.')

  if (arr.length > 1) {
    if (arr[1].length > 8) {
      return numberWithCommas(
        // exponentialToDecimal(parseFloat(amt).toFixed(8)).toString(),
        exponentialToDecimal(parseFloat(amt)).toString(),
      ).toString()
    } else {
      return numberWithCommas(amt).toString()
    }
  } else {
    if (amt) {
      return numberWithCommas(amt).toString()
    }
    return '0'
  }
}
export const exponentialToDecimal = (exponential) => {
  let decimal = exponential?.toString()?.toLowerCase()
  if (decimal?.includes('e+')) {
    const exponentialSplitted = decimal?.split('e+')
    let postfix = ''
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes('.')
          ? exponentialSplitted[0].split('.')[1].length
          : 0);
      i++
    ) {
      postfix += '0'
    }
    const addCommas = (text) => {
      let j = 3
      let textLength = text.length
      while (j < textLength) {
        text = `${text.slice(0, textLength - j)},${text.slice(
          textLength - j,
          textLength,
        )}`
        textLength++
        j += 3 + 1
      }
      return text
    }
    decimal = addCommas(exponentialSplitted[0].replace('.', '') + postfix)
  }
  if (decimal?.toLowerCase().includes('e-')) {
    const exponentialSplitted = decimal?.split('e-')
    let prefix = '0.'
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += '0'
    }
    decimal = prefix + exponentialSplitted[0].replace('.', '')
  }
  return decimal
}
const numberWithCommas = (x) => {
  let str = toFixedFunction(x, 6)

  let arr = str.split('.')

  let numbers = arr[0]
  let decimalNum = ''
  if (arr.length > 1) {
    decimalNum = arr[1]
    return `${numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${decimalNum}`
  } else {
    return numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

export const toFixedFunction = (amt, decimals) => {
  let str = amt?.toString()
  if (str?.includes('.')) {
    str = str.slice(0, str.indexOf('.') + (decimals + 1))
  }
  return str
}

export function generateUniqueAvatar(value) {
  return `https://robohash.org/${value}?set=set2&bgset=&size=400x400`
}
