import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  makeStyles,
  Switch,
  withStyles,
} from "@material-ui/core";
import { RiAddLine } from "react-icons/ri";
import React, { useState } from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { CoinList } from "src/data";
import { OpenWithRounded } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  formLogin: {
    // width: "450px",
    // maxWidth: "95%",
    "& h6": { fontWeight: "600" },
    "& label": {
      color: theme.palette.primary.main,
      marginBottom: "8px",
      display: " inline-block",
      fontWeight: "600",
    },
  },
  sideBarMenu: {
    "& img.icons": {
      marginRight: "12px",
      width: "24px",
    },
    "& svg": {
      marginRight: "12px",
      fontSize: "24px",
    },
    "& .subMenu": {
      marginTop: theme.spacing(2),
    },
  },
  iconColor: {
    "& img": {
      marginLeft: "10px",
      cursor: "pointer",
    },
  },
}));
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 38,
    height: 21,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    background: "none",
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(17px)",
      color: theme.palette.common.white,
      // backgroundColor: theme.palette.primary.main,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#7D56E7",
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 17,
    height: 17,
    boxShadow: "none",
    // backgroundColor: theme.palette.background.bg4box,
  },
  track: {
    // border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 1,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
export default function CreateRequestModal({ open, setOpen }) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  });

  const handleChange1 = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={OpenWithRounded}
      onClose={() => setOpen(false)}
    >
      <DialogContent>
        <Box className="dflexBetween">
          <Typography color="primary" variant="h6">
            Edit Portfolio
          </Typography>
          <IconButton style={{ padding: "0px" }} onClick={() => setOpen(false)}>
            <CloseOutlinedIcon color="secondary" />
          </IconButton>
        </Box>
        <Box mt={3} className={classes.formLogin}>
          <label>Connection Name</label>
          <TextField variant="outlined" fullWidth placeholder="MetaMask" />
          <Box my={2} className="dflexBetween">
            <label>Calculate Amount on Total</label>
            <AntSwitch
              checked={state.checkedC}
              onChange={handleChange1}
              name="checkedC"
            />
          </Box>
          <label variant="body2" color="primary">
            Total cost
          </label>

          <TextField variant="outlined" fullWidth placeholder="0" />
          <Box mt={2} className="dflexBetween">
            <label>Wallet / Exchange Connections</label>
          </Box>
          <Box mb={2}>
            <Paper elevation={1}>
              <Box
                className={classes.sideBarMenu}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box className="dflexBetween">
                  <img
                    src="images/coinlist/binance.svg"
                    alt=""
                    className="icons"
                  />
                  <Typography variant="body2" color="secondary">
                    metamask
                  </Typography>
                </Box>
                <Box className={`${classes.iconColor} dflexEnd `}>
                  <img src="images/edit.svg" alt="edit portfolio" />
                  <img src="images/delete.svg" alt="edit delete" />
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box mb={2}>
            <Paper elevation={1}>
              <Box
                className={classes.sideBarMenu}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box className="dflexBetween">
                  <img
                    src="images/coinlist/etherium.svg"
                    alt=""
                    className="icons"
                  />
                  <Typography variant="body2" color="secondary">
                    Etherium
                  </Typography>
                </Box>
                <Box className={`${classes.iconColor} dflexEnd `}>
                  <img src="images/edit.svg" alt="edit portfolio" />
                  <img src="images/delete.svg" alt="edit delete" />
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box mb={2}>
            <Paper elevation={1}>
              <Box
                className={classes.sideBarMenu}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box className="dflexBetween textpurple">
                  <RiAddLine />
                  <Typography variant="body2" color="secondary">
                    Connect New
                  </Typography>
                </Box>
                <Box className={`${classes.iconColor} dflexEnd `}>
                  <img src="images/edit.svg" alt="edit portfolio" />
                  <img src="images/delete.svg" alt="edit delete" />
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
        <Box mt={2} mb={1} className="dflexEnd">
          <Button color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
