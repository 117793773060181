export default {
  h1: {
    fontWeight: 600,
    fontSize: "3.4rem",
    lineHeight: 1.4,
    "@media(max-width: 1440px)": {
      fontSize: "2.8rem",
    },
    "@media(max-width: 767px)": {
      fontSize: "2rem",
    },
    "@media screen and (max-height: 768px)": {
      fontSize: "2.6rem",
    },
  },
  h2: {
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: "3rem",
    wordBreak: "break-word",
    "@media(max-width: 1440px)": {
      fontSize: "1.55rem",
      lineHeight: "2.375rem",
    },
    "@media(max-width: 767px)": {
      fontSize: "1.3rem",
      lineHeight: "2.375rem",
    },
  },
  h3: {
    fontWeight: 500,
    fontSize: "2.1875rem",
    wordBreak: "break-word",
    "@media(max-width: 1440px)": {
      fontSize: "1.4575rem",
      lineHeight: "2.375rem",
    },
    "@media(max-width: 767px)": {
      fontSize: "1.175rem",
    },
  },
  h4: {
    fontWeight: 500,
    fontSize: "1.75rem",
    wordBreak: "break-word",
    "@media(max-width: 1440px)": {
      fontSize: "1.2125rem",
      lineHeight: "2.125rem",
    },
    "@media(max-width: 767px)": {
      fontSize: "1rem",
    },
  },
  h5: {
    fontWeight: 600,
    fontSize: "1.25rem",
    wordBreak: "break-word",
    "@media(max-width: 1440px)": {
      fontSize: "1.0625rem",
      lineHeight: "1.5rem",
    },
    "@media(max-width: 767px)": {
      fontSize: "1rem",
    },
  },
  h6: {
    fontWeight: 400,
    fontSize: "1rem",
    letterSpacing: "0.075rem",
    "@media(max-width: 1440px)": {
      fontSize: "0.875rem",
      lineHeight: "1.5rem",
    },
    "@media(max-width: 767px)": {
      fontSize: "0.8125rem",
      lineHeight: "1.1875rem",
    },
  },
  body1: {
    fontSize: "0.75rem",
    fontWeight: 300,
    lineHeight: "1.0625rem",
    "@media(max-width: 767px)": {
      fontSize: "0.6875rem !important",
      lineHeight: "1.0625rem",
    },
    "@media (max-width: 1440px)": {
      fontSize: "0.75rem",
    },
  },
  body2: {
    fontSize: "0.84375rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    letterSpacing: "0.045rem",
    "@media(max-width: 1440px)": {
      fontSize: "0.8125rem",
      lineHeight: "1.3125rem",
    },
    "@media(max-width: 767px)": {
      fontSize: "0.75rem !important",
      lineHeight: "1.125rem !important",
    },
  },
  subtitle: {
    fontSize: "0.64375rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    letterSpacing: "0.045rem",
    "@media(max-width: 1440px)": {
      fontSize: "0.8125rem",
      lineHeight: "1.3125rem",
    },
    "@media(max-width: 767px)": {
      fontSize: "0.75rem !important",
      lineHeight: "1.125rem !important",
    },
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
  },
};
