import React, { useState } from "react";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  ListSubheader,
  Box,
  Typography,
  Paper,
} from "@material-ui/core";
import { BiSearch } from "react-icons/bi";
import { ClickAwayListener } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  posrel: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    position: "Relative",
  },
  inputInput: {
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
    "& .MuiInputBase-input": {
      height: "25px",
      padding: "6px 20px",
    },
    "& .MuiOutlinedInput-root": { background: theme.palette.background.bg4box },
  },
}));

const SearchField = () => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [recentSearches, setRecentSearches] = useState([
    "React",
    "Material-UI",
    "Search",
    "Dropdown",
  ]);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleInputClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSearch = () => {
    // Perform search logic
    // Add searchValue to recentSearches array
    // Clear search input
    // Close dropdown
  };

  const handleClickAway = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div className={classes.posrel}>
      <TextField
        className={classes.inputInput}
        value={searchValue}
        onChange={handleInputChange}
        onClick={handleInputClick}
        placeholder="Search address / Asset"
        variant="outlined"
        InputProps={{
          endAdornment: <BiSearch />,
        }}
      />
      {isDropdownOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            component={Paper}
            className="SearchResultBox"
            style={{ padding: "0px", borderRadius: "0", marginTop: "15px" }}
          >
            <Box className="dflexBetween" px={2} pt={1}>
              <Typography variant="body1" color="secondary">
                Recent searches
              </Typography>{" "}
              <Typography variant="body1" color="secondary">
                Clear all
              </Typography>
            </Box>
            <List>
              {recentSearches.map((search, index) => (
                <ListItem button key={index}>
                  <ListItemText primary={search} />
                </ListItem>
              ))}
            </List>{" "}
          </Box>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default SearchField;
