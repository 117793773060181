import React, { useContext } from "react";
import SettingsContext from "src/context/SettingsContext";
import { Box, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  logoBox: {
    "& img": {
      marginRight: theme.spacing(2),
    },
  },
}));
const Logo = (props) => {
  const themeSeeting = useContext(SettingsContext);
  const classes = useStyles();
  return (
    <>
      <div className="mobHide">
        {themeSeeting.settings.theme === "DARK" ? (
          <Box className={`${classes.logoBox} `}>
            <img src="/images/logo_light.svg" alt="Logo" />
            {/* <Typography variant="h4" color="primary" {...props}>
              BlockStats
            </Typography> */}
          </Box>
        ) : (
          <Box className={`${classes.logoBox} `}>
            <img
              className="mobileShow"
              src="/images/logo_dark.svg"
              alt="Logo"
            />
          </Box>
        )}
      </div>
      <div className="deskHide">
        <img className="mobileShow" src="/images/logo_mobile.svg" alt="Logo" />
      </div>
    </>
  );
};

export default Logo;
