import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box } from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";
import TopBar from "../HomeLayout/TopBar";

const useStyles = makeStyles((theme) => ({
  headbox: {
    backgroundPosition: "center bottom",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    margin: "0",
    padding: "0",
    position: "fixed",
    width: "100%",
    height: "100dvh",
    // [theme.breakpoints.down("xs")]: {
    //   position: "relative",
    // },
    overflow: "auto",
  },
  content: {
    minHeight: "calc(100vh - 130px)",
    padding: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    maxWidth: "439px",
    margin: "65px auto 0px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 122px)",
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const themeSeeting = useContext(SettingsContext);
  return (
    <>
      <Box
        className={classes.headbox}
        style={
          themeSeeting.settings.theme === "LIGHT"
            ? { background: "url(./images/backgroundLight.svg)" }
            : { background: "url(./images/backgroundDark.svg)" }
        }
      >
        <TopBar />

        <Box className={classes.content}>{children}</Box>
      </Box>
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
