import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box } from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";
import TopBar from "../HomeLayout/TopBar";

const useStyles = makeStyles((theme) => ({
  headbox: {
    background: theme.palette.background.main,
    margin: "0",
    padding: "0",
    position: "fixed",
    width: "100%",
    height: "100dvh",
    // [theme.breakpoints.down("xs")]: {
    //   position: "relative",
    // },
  },
  content: {
    height: "100dvh",
    padding: "0",

    overflowY: "auto",
    overflowX: "hidden",
    margin: "0 auto",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const themeSeeting = useContext(SettingsContext);
  return (
    <>
      <Box className={classes.headbox}>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
