export const BASE_URL = process.env.PLATFORM_BASE_URL
const redirect_URL = 'http://localhost:4545/'
export const dateFormat = 'YYYY/MM/DD hh:mm:ss'

export function walletImages(name) {
  switch (name) {
    case 'KUCOIN':
      return 'images/coinlist/kucoin.svg'
    case 'COINBASE':
      return 'images/coinlist/coinbase_big.svg'
    case 'WALLET ADDRESS':
      return 'images/coinlist/Bitcoinwallet.png'
  }
}
