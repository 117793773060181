import React from 'react'
import { Box, TextField, makeStyles, InputAdornment } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  formLogin: {
    marginBottom: '10px',
    '& label': {
      color: theme.palette.primary.main,
      marginBottom: '8px',
      display: ' inline-block',
      fontWeight: '600',
    },
    '& p': {
      paddingTop: '4px !important',
      marginLeft: '0px !important',
    },
  },
}))

export default function CommonField({
  placeholder,
  label,
  onChange,
  value,
  error,
  helperText,
  inputPropStartJSX,
}) {
  const classes = useStyles()
  return (
    <Box className={classes.formLogin}>
      <label>{label}</label>
      <TextField
        placeholder={placeholder}
        variant="outlined"
        fullWidth
        color="secondary"
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        InputProps={
          inputPropStartJSX && {
            endAdornment: (
              <InputAdornment position="end">
                {inputPropStartJSX}
              </InputAdornment>
            ),
          }
        }
      />
    </Box>
  )
}
