import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import { getAPIHandler, putAPIHandler } from 'src/Apiconfig/service'
import axios from 'axios'
import CommonField from '../FormFields/CommonField'
import { toast } from 'react-hot-toast'
import ScreenLoader from '../ScreenLoader'
const useStyles = makeStyles((theme) => ({
  formLogin: {
    // width: "450px",
    // maxWidth: "95%",
    '& h6': { fontWeight: '600' },
    '& label': {
      color: theme.palette.primary.main,
      marginBottom: '8px',
      display: ' inline-block',
      fontWeight: '600',
    },
  },
  sideBarMenu: {
    '& img.icons': {
      marginRight: '12px',
      width: '24px',
    },
    '& svg': {
      marginRight: '12px',
      fontSize: '24px',
    },
    '& .subMenu': {
      marginTop: theme.spacing(2),
    },
  },
  iconColor: {
    '& img': {
      marginLeft: '10px',
      cursor: 'pointer',
    },
  },
}))

export default function EditExchange({
  open,
  close,
  userId,
  walletId,
  auth,
}) {
  const classes = useStyles()
  const [isSubmit, setIsSubmit] = useState(false)
  const [formData, setFormData] = useState({})
  const [walletDetails, setWalletDetails] = useState({})
  const getParticualrWalletDetails = async (source) => {
    try {
      const response = await getAPIHandler({
        endPoint: 'wallet',
        source: source,
        id: `${userId}/${walletId}`,
      })
      if (response.status === 200) {
        setWalletDetails(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updateWalletDetailsHandler = async () => {
    try {
      setFormData({ ...formData, ['isProcessing']: true })
      const response = await putAPIHandler({
        endPoint: 'wallet',
        id: walletId,
        userId: userId,
        data: {
          walletName: walletDetails.walletName,
          authType: walletDetails.authType,
          walletDetails: formData,
          active: true,
          expiresAt: new Date(),
        },
      })
      if (response.status == 200) {
        auth.getAllAddedWallets()
        toast.success('Wallet has been updated successfully!')
        close()
      }
      setFormData({ ...formData, ['isProcessing']: false })
    } catch (error) {
      console.log(error)
      setFormData({ ...formData, ['isProcessing']: false })
    }
  }

  useEffect(() => {
    const source = axios.CancelToken.source()
    getParticualrWalletDetails(source)
    return () => {
      source.cancel()
    }
  }, [])

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={close}>
      <DialogContent>
        <Box className="dflexBetween">
          <Typography color="primary" variant="h6">
            Edit Wallet Details
          </Typography>
          <IconButton style={{ padding: '0px' }} onClick={close}>
            <CloseOutlinedIcon color="secondary" />
          </IconButton>
        </Box>
        <Box mt={3} className={classes.formLogin}>
          <CommonField
            label={
              walletDetails?.walletName === 'KUCOIN'
                ? 'API Key'
                : 'Verification Code'
            }
            placeholder={
              walletDetails?.walletName === 'KUCOIN'
                ? 'Enter api key here'
                : 'Enter your verification code.'
            }
            value={
              formData[
                walletDetails?.walletName === 'KUCOIN' ? 'apiKey' : 'userCode'
              ]
            }
            onChange={(e) =>
              setFormData({
                ...formData,
                [walletDetails?.walletName === 'KUCOIN'
                  ? 'apiKey'
                  : 'userCode']: e.target.value,
              })
            }
            error={
              isSubmit &&
              formData[
                walletDetails?.walletName === 'KUCOIN' ? 'apiKey' : 'userCode'
              ] === ''
            }
            helperText={
              isSubmit &&
              formData[
                walletDetails?.walletName === 'KUCOIN' ? 'apiKey' : 'userCode'
              ] === '' &&
              'This field can not be empty.'
            }
          />
          {walletDetails?.walletName === 'KUCOIN' && (
            <>
              <CommonField
                label="API Secret"
                placeholder="Enter secret key here"
                value={formData.apiSecret}
                onChange={(e) =>
                  setFormData({ ...formData, ['apiSecret']: e.target.value })
                }
                error={isSubmit && formData.apiSecret === ''}
                helperText={
                  isSubmit &&
                  formData.apiSecret === '' &&
                  'Please enter api secret key.'
                }
              />
              <CommonField
                label="API Passphrase"
                placeholder="Enter passphrase here"
                value={formData.apiPassphrase}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    ['apiPassphrase']: e.target.value,
                  })
                }
                error={isSubmit && formData.apiPassphrase === ''}
                helperText={
                  isSubmit &&
                  formData.apiPassphrase === '' &&
                  'Please enter api passphrase.'
                }
              />
            </>
          )}
        </Box>
        <Box mt={2} mb={1} className="dflexEnd">
          <Button color="secondary" onClick={close}>
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={updateWalletDetailsHandler}
          >
            Submit
          </Button>
        </Box>
      </DialogContent>
      {formData.isProcessing && (
        <ScreenLoader
          open={formData.isProcessing}
          close={() => setFormData({ ...formData, ['isProcessing']: false })}
        />
      )}
    </Dialog>
  )
}
