import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Box,
  Container,
} from '@material-ui/core'
import SettingsContext from 'src/context/SettingsContext'
import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logo from './../../component/Logo'
import { BiMoon, BiSun } from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import { AuthContext } from 'src/context/Auth'
export default function Header() {
  const auth = useContext(AuthContext)
  const themeSeeting = useContext(SettingsContext)
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    })
  }
  const [scroll, setScroll] = useState(false)
  const history = useHistory()
  useEffect(() => {
    window.addEventListener('scroll', () => {
      // console.log(window.scrollY);
      setScroll(window.scrollY > 250)
    })
  }, [])
  const displayDesktop = () => {
    return (
      <Toolbar className="NavBar dflexBetween ">
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {topMenu}
        </Box>
      </Toolbar>
    )
  }

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  )
  const topMenu = (
    <Box>
      {/* <IconButton
        className="themeset"
        variant="contained"
        color="primary"
        aria-label="Change Theme"
      >
        <TbSettings style={{ color: "#303030" }} />
      </IconButton> */}

      {themeSeeting.settings.theme === 'LIGHT' && (
        <IconButton
          className="themeset"
          variant="contained"
          color="primary"
          aria-label="Change Theme"
          onClick={() => {
            changeTheme('DARK')
          }}
        >
          <BiSun style={{ color: 'rgb(84 84 84)' }} />
        </IconButton>
      )}
      {themeSeeting.settings.theme === 'DARK' && (
        <IconButton
          className="themeset"
          variant="contained"
          color="primary"
          aria-label="Change Theme"
          onClick={() => {
            changeTheme('LIGHT')
          }}
        >
          <BiMoon style={{ color: 'rgb(84 84 84)' }} />
        </IconButton>
      )}

      {!auth.userLoggedIn && (
        <>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            to="/login"
            component={Link}
            style={{ margin: '0 10px' }}
          >
            Log In
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            to="/get-started"
            component={Link}
          >
            Get Started
          </Button>
        </>
      )}
      {auth.userLoggedIn && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          to="/portfolio"
          component={Link}
          style={{ margin: '0 10px' }}
        >
          Dashboard
        </Button>
      )}
    </Box>
  )

  return (
    <>
      <AppBar
        position={history.location.pathname !== '/' ? 'relative' : 'absolute'}
        elevation={0}
        className={
          themeSeeting.settings.theme === 'DARK'
            ? `${scroll ? 'navbar-scroll_dark' : 'navbar'}`
            : `${scroll ? 'navbar-scroll' : 'navbar'}`
        }
        style={{
          backgroundColor: 'transparent',
          borderBottom: ' 1px solid rgb(137 126 126 / 22%)',
          borderRadius: '0px',
        }}
      >
        <Container maxWidth="lg">{displayDesktop()}</Container>
      </AppBar>
    </>
  )
}
