// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAOLYZj26o-7QpyaKX1z-uXSCI4gWkoxhU',
  authDomain: 'dev-prj-frontend-apps.firebaseapp.com',
  projectId: 'dev-prj-frontend-apps',
  storageBucket: 'dev-prj-frontend-apps.appspot.com',
  messagingSenderId: '594081517864',
  appId: '1:594081517864:web:286fb73c36177dd82fc01d',
  measurementId: 'G-VRMXVH6KQX',
}
// Initialize Firebase
const app = initializeApp(firebaseConfig)

const firebaseAuth = getAuth()

export { app, firebaseAuth }
