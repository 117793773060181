import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
const useStyles = makeStyles((theme) => ({
  formLogin: {
    // width: "450px",
    // maxWidth: "95%",
    "& h6": { fontWeight: "600" },
    "& label": {
      color: theme.palette.primary.main,
      marginBottom: "8px",
      display: " inline-block",
      fontWeight: "600",
    },
  },
  sideBarMenu: {
    "& img.icons": {
      marginRight: "12px",
      width: "24px",
    },
    "& svg": {
      marginRight: "12px",
      fontSize: "24px",
    },
    "& .subMenu": {
      marginTop: theme.spacing(2),
    },
  },
  iconColor: {
    "& img": {
      marginLeft: "10px",
      cursor: "pointer",
    },
  },
}));

export default function EditWallet({ openWallet, setOpenWallet }) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={openWallet}
      onClose={() => setOpenWallet(false)}
    >
      <DialogContent>
        <Box className="dflexBetween">
          <Typography color="primary" variant="h6">
            Edit Portfolio
          </Typography>
          <IconButton
            style={{ padding: "0px" }}
            onClick={() => setOpenWallet(false)}
          >
            <CloseOutlinedIcon color="secondary" />
          </IconButton>
        </Box>
        <Box mt={3} className={classes.formLogin}>
          <label>Connection Name</label>
          <TextField variant="outlined" fullWidth placeholder="MetaMask" />

          <Box mt={2}>
            <label>Wallet </label>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Polygon Wallet"
            />
          </Box>
          <Box mt={2}>
            <label>Wallet Address</label>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="0x4df46c869d9c607b86c1ba699d695e1c6b4f1953"
            />
          </Box>
        </Box>
        <Box mt={2} mb={1} className="dflexEnd">
          <Button color="secondary" onClick={() => setOpenWallet(false)}>
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
