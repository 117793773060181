// import { BASE_URL } from 'src/constant'
export const api_base_url = 'https://blockstats-gateway-7kx0sox4.uc.gateway.dev'
// const api_base_url2 = 'https://aggregator-service-uw6myk4s3a-uc.a.run.app'

let version = 'v1'
let walleturl = `${api_base_url}/blockstats/walletservice/${version}`
let aggregatorurl = `${api_base_url}/blockstats/aggregator/${version}`
let portfolio = `${api_base_url}/blockstats/portfolioservice/${version}`
let defi = `${aggregatorurl}/defi`

const ApiConfig = {
  wallet: `${walleturl}/wallets`,

  //aggregator_services_api
  spotHoldings: `${aggregatorurl}/accounts`,
  openOrders: `${aggregatorurl}/orders`,
  transactions: `${aggregatorurl}/transactions`,
  margins: `${aggregatorurl}/margins`,
  futures: `${aggregatorurl}/futures`,

  //portfolio_services_api
  load: `${portfolio}/load`,
  walletStatus: `${portfolio}/status`,

  //aggregator_defi_services_api
  defiAccounts: `${defi}/accounts`,
  defiTransactions: `${defi}/transactions`,
  defiStakings: `${defi}/stakings`,

  //api_to_get_current_balances
  currentBalance: `${aggregatorurl}/accounts/currentBalance`,
  marginsBalance: `${aggregatorurl}/margins/currentBalance`,
  futuresBalance: `${aggregatorurl}/futures/currentBalance`,
}

export default ApiConfig
