import React, { useState } from 'react'
import { Box, Button, Paper, Typography, makeStyles } from '@material-ui/core'
// import { RiDeleteBin7Line } from "react-icons/ri";
// import { SlMenu } from "react-icons/sl";
import EditPortfolio from './EditPortfolio'
import EditWallet from './EditWallet'
import WalletCard from 'src/component/WalletCard'
const useStyles = makeStyles((theme) => ({
  sideBarMenu: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    '& img.icons': {
      marginRight: '12px',
      width: '20px',
    },
    '& .subMenu': {
      marginTop: theme.spacing(2),
    },
  },
  iconColor: {
    '& img': {
      marginLeft: '10px',
      cursor: 'pointer',
    },
  },
}))

export default function Security({ userAddedWallets }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [openWallet, setOpenWallet] = useState(false)
  return (
    <Box className={classes.sideBarMenu}>
      <Paper elevation={1} className="activeSide">
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <img src="/images/logo_mobile.svg" alt="" className="icons" />
          <Typography variant="body2" color="primary">
            All Assets
          </Typography>
        </Box>
      </Paper>
      {/* <Paper elevation={1}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <img src="images/coinlist/metamask.svg" alt="" className="icons" />
          <Typography variant="body2" color="secondary">
            MetaMask
          </Typography>
        </Box>
        <Box className="subMenu">
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box className="sideTree"></Box>
            <img src="images/coinlist/polygon.svg" alt="" className="icons" />
            <Typography variant="body2" color="secondary">
              Polygon
            </Typography>
          </Box>
        </Box>
        <Box className="subMenu">
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box className="sideTree"></Box>
            <img src="images/coinlist/etherium.svg" alt="" className="icons" />
            <Typography variant="body2" color="secondary">
              Etherium
            </Typography>
          </Box>
        </Box>
        <Box className="subMenu">
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box className="sideTree"></Box>
            <img src="images/binancedex.svg" alt="" className="icons" />
            <Typography variant="body2" color="secondary">
              BinanceSC
            </Typography>
          </Box>
        </Box>
      </Paper> */}

      {userAddedWallets &&
        userAddedWallets.map((data, i) => {
          return <WalletCard classes={classes} data={data} index={i} />
        })}
      {open && <EditPortfolio open={open} setOpen={(item) => setOpen(item)} />}
      {openWallet && (
        <EditWallet
          open={openWallet}
          setOpenWallet={(item) => setOpenWallet(item)}
        />
      )}
    </Box>
  )
}
