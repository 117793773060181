import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Box, Container, Hidden, Button } from '@material-ui/core'
import TopBar from './TopBar'
import SideBar from './SideBar'
import { Link } from 'react-router-dom'
import { AuthContext } from 'src/context/Auth'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.main,
    // display: "flex",
    minHeight: '100vh',

    // overflow: 'auto',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',

    // overflow: "hidden",
    paddingTop: 30,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    background: theme.palette.background.dark1,
    '@media (max-width: 1278px)': {
      display: 'block',
    },
  },
  Sidebar: {
    width: '240px',
    minWidth: '240px',
    height: 'calc(100dvh - 102px)',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    // "@media screen and (max-height: 768px)": {
    //   width: "200px",
    //   minWidth: "200px",
    // },
  },
  content: {
    flex: '1 1 auto',
    // height: "calc(100% - 95px)",
    // overflow: "hidden",

    position: 'relative',
    WebkitOverflowScrolling: 'touch',
    padding: '0px 0px 15px 30px ',
    '@media (min-width: 1280px)': {
      height: 'calc(100dvh - 114px)',
      overflow: 'auto',
      overflowX: 'hidden',
    },
    '@media (max-width: 1279px)': {
      padding: '0px ',
    },
  },
}))

const DashboardLayout = ({ children }) => {
  const classes = useStyles()
  const auth = useContext(AuthContext)
  const { userAddedWallets } = auth

  return (
    <div className={classes.root}>
      <TopBar />

      {/* <div className={`${classes.wrapper} mainMobileView`} id="scrollableDiv"> */}
      <div className={`${classes.wrapper}`}>
        <Container maxWidth={false}>
          <div className={classes.contentContainer}>
            <Hidden mdDown>
              <Box className={classes.Sidebar}>
                <SideBar userAddedWallets={userAddedWallets} />
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  component={Link}
                  to="/add-assets"
                >
                  Add Assets
                </Button>
              </Box>
            </Hidden>
            <div className={`${classes.content} noscroll`} id="main-scroll">
              {children}
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
}

export default DashboardLayout
