import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
export default function ConfirmationDialog({
  open,
  close,
  title,
  onClick,
  description,
}) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={close}>
      <DialogContent>
        <Box className="dflexBetween">
          <Typography color="primary" variant="h6">
            {title}
          </Typography>
          <IconButton style={{ padding: '0px' }} onClick={close}>
            <CloseOutlinedIcon color="secondary" />
          </IconButton>
        </Box>
        <Box mt={3}>
          <Typography color="primary" variant="body2">
            {description}
          </Typography>
        </Box>
        <Box mt={2} mb={1} className="dflexEnd">
          <Button color="secondary" onClick={close}>
            Close
          </Button>
          &nbsp;&nbsp;
          <Button variant="contained" color="primary" onClick={onClick}>
            Yes
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
