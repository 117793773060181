import { AuthContext } from 'src/context/Auth'
import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'

export default function AuthGuard(props) {
  const { children } = props
  const auth = useContext(AuthContext)
  const { userLoggedIn } = auth
  if (!userLoggedIn) {
    return <Redirect to="/" />
  }

  return <>{children}</>
}
